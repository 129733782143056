<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
              
        <div class="va-card">
          <div class="row">
            <div class="col-md-12">
              <div class="dash_card_body">
                <div class="header_section">
                  <h4 class="menu_header">Linked Stores</h4>
                  <div class="">
                    <button data-toggle="modal" data-target="#link_store" class="btn btn-default custom_btn custon_orange">
                      <i class="fa fa-plus"></i> Link Store
                    </button>

                    <!-- modal -->
                    <div class="modal fade" id="link_store" role="dialog">
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">Link Store
                          </div>
                          <form @submit.prevent="linkStore()">
                            <div class="modal-body">
                              <div class="error_msgs" v-if="errors">
                                <div class="alert alert-danger alert-dismissible fade show" role="alert">
                                  <ul>
                                    <li v-for="error in errorMsg" :key="error">{{error}}</li>
                                  </ul>
                                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                              </div>
                              <div class="form-group">
                                <label  for="mbl_type">Store:</label>
                                <div class="mt-3">
                                  <Multiselect v-model="storeId" :close-on-select="true"
                                    :options="strsoptions" :custom-label="name" :searchable="true"
                                    :placeholder="'Stores'" label="name" track-by="name">
                                  </Multiselect>
                                
                                 
                                  <!-- <div class="input_error" v-if="v$.menuType.$error">
                                    <span v-if="v$.menuType.required.$invalid"><small>Menu type is required</small></span>
                                  </div> -->
                                </div>
                              </div>
                              <div class="form-group">
                                <label  for="mbl_type">Remote Store Id:</label>
                                <div class="mt-3">
                                  <input type="text" class="form-control" v-model="remoteStoreKey">
                                </div>
                              </div>
                              
                            </div>
                            
                            <div class="modal-footer">
                              <button type="button" id="link_close_btn" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                              <button type="submit" class="btn btn-orange"><span v-if="spinner"><i class="fa fa-spinner fa-spin"></i></span>Link</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>

                    <!--  -->
                  </div>
                </div>
                <div class="search_section">
                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <div class="form-group has-search mt-1">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" v-model="search" @keyup="searchStores()" aria-required="false" autocomplete="on" inputmode="text" name="search_input" placeholder="Search..."   class="form-control" >
                      </div>
                    </div>
  
                    <div class="col-md-2 col-sm-12">
                      <div class="form-group has-search">
                        <button class="btn btn-orange search mt-1">Search</button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                      <select class="form-control mt-1" @change="searchStores()" v-model="storeStatus">
                        <option value="">--Select status --</option>
                        <option value="1">Published</option>
                        <option value="2">Unpublished</option>
                        <option value="4">Trashed</option>
                        <option value="5">All</option>
                      </select>
                    </div>
                  
                  </div>
                </div>
  
  
              </div>
              <hr>
            </div>
  
            
            
              
              
             
            <div class="col-lg-12">
              <div class="dash_card_body pt-0">
                <div class="table_section storeList">
                  <table class="table  table-hover">
                    <thead>
                      <tr>
                        <th width="5px">
                          <input type="checkbox" id="check_all">
                        </th>
                        <th scope="col">Name</th>
                        <th scope="col">Linked Store</th>
                        <th scope="col">Store Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody v-if="!loading">
                      <tr v-for="store in stores" :key="store.id">
                        <td><input type="checkbox" :id="'store_'+store.id"></td>
                        
                        <td>
                          <!-- <router-link :to="{ name: 'updatestore', params: { storeid:store.id }}"> -->
                          {{store.branch_name}}
                          <!-- </router-link> -->
                        </td>
                        <td>{{ store.store_key }}</td>
                        <td>
                          <div class = "btn-group">
                            <button class="btn custom_btn btn-sm dropdown-toggle'" type="button"  >
                              <span v-if="store.status == 1">
                                <i class='fa fa-check' style="color:green;"></i> 
                              </span>
                              <span v-else-if="store.status == 2">
                                <i class="fa fa-close" style="color:red;" aria-hidden="true"></i>
                              </span>
                              <span v-else-if="store.status == 3">
                                <i class="fa fa-archive" aria-hidden="true"></i>
                              </span>
                              <span v-else>
                                <i class="fa fa-trash"  aria-hidden="true"></i>
                              </span>
                            </button>
    
                            
                          </div>
                            
  
                        </td>
                        <td>
                          <button class="btn btn-purple btn-sm mr-1" @click="editStoreLink(store)"  data-toggle="modal" :data-target="`#edit_link_store${store.id}`" ><i class='fas fa-edit'></i></button>
                        </td>
                        <!--edit modal -->
                        <div class="modal fade" :id="`edit_link_store${store.id}`" role="dialog">
                          <div class="modal-dialog">
                            <div class="modal-content">
                              <div class="modal-header">Edit Link Store ({{ store.branch_name }})
                              </div>
                              <form @submit.prevent="linkStore()">
                                <div class="modal-body">
                                  <div class="error_msgs" v-if="errors">
                                    <div class="alert alert-danger alert-dismissible fade show" role="alert">
                                      <ul>
                                        <li v-for="error in errorMsg" :key="error">{{error}}</li>
                                      </ul>
                                      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                  </div>
                                  <div class="form-group">
                                    <label  for="mbl_type">Store:</label>
                                    <div class="mt-3">
                                      <Multiselect v-model="storeId" :close-on-select="true"
                                        :options="strsoptions" :custom-label="name" :searchable="true"
                                        :placeholder="'Stores'" label="name" track-by="name">
                                      </Multiselect>
                                
                                 
                                      <!-- <div class="input_error" v-if="v$.menuType.$error">
                                    <span v-if="v$.menuType.required.$invalid"><small>Menu type is required</small></span>
                                  </div> -->
                                    </div>
                                  </div>
                                  <div class="form-group">
                                    <label  for="mbl_type">Remote Store Id:</label>
                                    <div class="mt-3">
                                      <input type="text" class="form-control" v-model="remoteStoreKey">
                                    </div>
                                  </div>
                              
                                </div>
                            
                                <div class="modal-footer">
                                  <button type="button" @click="cancelLink" :id="`link_close_btn_${store.id}`" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                                  <button type="submit" class="btn btn-orange"><span v-if="spinner"><i class="fa fa-spinner fa-spin"></i></span>Link</button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <!-- end -->
                      </tr>
  
                    </tbody>
                    <tfoot v-if="!loading">
                      <tr>
                        <td colspan="8">
                          <div id="pagination" v-if="totalPages > 1">
                            <ul class="showItems">
                              <li>Show Items:
                                <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                  <option :value="10">10</option>
                                  <option :value="20">20</option>
                                  <option :value="30">30</option>
                                  <option :value="40">40</option>
                                  <option :value="50">50</option>
                                </select>
                              </li>
                              
                            </ul>
                            <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />
  
                          </div>
                            
                        </td>
                      </tr>
                    </tfoot>
                  </table>
  
                </div>
                <div v-if="loading" class="loading-cover">
                  <div class="loader"> </div>
                </div>
  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins'
  import ReusableDataMixins from '@/mixins/ReusableDataMixins';
  import Pagination from '@/pages/pagination/pagination.vue';
  import Multiselect from '@vueform/multiselect';
  import { useToast } from "vue-toastification";
  const toast = useToast();
  export default {
      components: {
          Pagination,
          Multiselect
      },
      data() {
          return {
              stores: [],
              loading: false,
              page: 1,
              totalPages: 0,
              totalRecords: 0,
              recordsPerPage: 10,
              enterpageno: '',
              storeIds: [],
              storeStatus: '',
              methodSpinner:null,
              debounce: null,
              spinner: false,
              search:'',
              storeSelected: 0,
              strsoptions:[],
              remoteStoreKey: '',
              storeId:'',
              errors:false,
              errorMsg:[]
              // storeIds: []
          }
      },
      created: async function () {
          this.getBranches();
          this.getAllBranches();
      },
      methods: {
        editStoreLink(store){
            this.storeId = store.id
            this.remoteStoreKey = store.store_key
        },
        cancelLink(){
            this.storeId = ''
            this.remoteStoreKey = ''
        },
        searchStores() {
              clearTimeout(this.debounce);
              this.debounce = setTimeout(() => {
                  this.getBranches();
              }, 1000)
          },
          async linkStore(){
            this.spinner = true
            try {
                const url = `${this.dashBaseUrl}/link-store/${this.merchantID}`;
                const payload ={
                    'store_id': this.storeId,
                    'store_key': this.remoteStoreKey
                }
                const res = await this.axios.post(url,payload,this.apiRequestConfig())
                if(res.status == 201){
                    this.spinner = false;
                    toast.success("store linked successfully")
                    this.getBranches();
                    document.getElementById('link_close_btn').click();
                    if(document.getElementById(`link_close_btn_${this.storeId}`)){
                        document.getElementById(`link_close_btn_${this.storeId}`).click()
                    }
                }
            } catch (error) {
                document.getElementById('link_close_btn').click();
                if(document.getElementById(`link_close_btn_${this.storeId}`)){
                    document.getElementById(`link_close_btn_${this.storeId}`).click()
                }
                if (error.response && error.response.status === 400) {
                    this.errors = true;
                    this.errorMsg = error.response.data.errors;
                }else if (error.response && error.response.status === 401) {
                        this.$router.push({
                            name: 'login'
                        });
                }
            }
          },
          async getAllBranches() {
            const url = this.baseUrl + "/branch?merchant_id=" + this.merchantID;
            this.axios.get(url, this.apiRequestConfig()).then((response) => {
                if (response.status === 200) {
                    if (response.data.data !== null) {
                        const stores = response.data.data;
                        this.strsoptions.push({
                                      "value": "",
                                      "name": "All Stores ("+ stores.length +")"
                        })
                        for (let i = 0; i < stores.length; i++) {
                            this.strsoptions.push({
                                "value": stores[i].id,
                                "name": stores[i].branch_name
                            })
                        }
                    }
                }
            }).catch((error) => {
              if (error.response && error.response.status === 401) {
                            this.$router.push({
                                name: 'login'
                            });
                    }
            })

        },
       
        
        
          async getBranches() {
              // const url = `${this.baseUrl}//branch?page=${this.page}&size=${this.recordsPerPage}&merchant_id=${this.merchantID}&query=${this.search}`;
  
            let url = `${this.dashBaseUrl}/third-party-store/${this.merchantID}`;
            url  = new URL(url);
            url.searchParams.set('page',this.page);
            url.searchParams.set('size',this.recordsPerPage);
            if(this.storeStatus !==''){
              url.searchParams.set('status',this.storeStatus);
            }
            if(this.search !==''){
              url.searchParams.set('search',this.search);
            }  
              this.loading = true;
              const config = {
                          headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                    };
              this.axios.get(url,config).then((response) => {
                  if (response.status === 200) {
                      this.loading = false;
                      this.stores = response.data.data.data;
                      this.totalPages = Math.ceil(response.data.data.total / this.recordsPerPage)
                      this.totalRecords = response.data.data.total   
                  }
              }).catch((error) => {
                  if (error.response.status === 401) {
                              this.$router.push({
                                  name: 'login'
                              });
                      }
              })
  
          },
          onPageChange(page) {
              this.storeIds = []
             
              this.page = page
              this.getBranches();
          },
          onChangeRecordsPerPage() {
            this.storeIds = []
            this.getBranches();
              // ds
          },
         
      },
      mixins: [ReusableFunctionMixins,ReusableDataMixins]
  }
  </script>
  